/* src/fonts/fontface.css */
@font-face {
  font-family: "OpenSans";
  src:
    url("./OpenSans-Regular-IPJVKKZA.woff2") format("woff2"),
    url("./OpenSans-Regular-7NI5R7D6.woff") format("woff"),
    url("./OpenSans-Regular-PFNGOB2K.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src:
    url("./OpenSans-Medium-3DVTI55M.woff2") format("woff2"),
    url("./OpenSans-Medium-J2NHEQEC.woff") format("woff"),
    url("./OpenSans-Medium-KZCZ6QQ4.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src:
    url("./OpenSans-SemiBold-XKDJOUAK.woff2") format("woff2"),
    url("./OpenSans-SemiBold-5HR26KW5.woff") format("woff"),
    url("./OpenSans-SemiBold-V337ZMN3.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src:
    url("./OpenSans-Bold-7NZ63AVY.woff2") format("woff2"),
    url("./OpenSans-Bold-6YGXFRFE.woff") format("woff"),
    url("./OpenSans-Bold-4X47SGAL.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
