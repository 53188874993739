/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

@import 'aesirx-uikit/dist/utils/bootstrap.scss';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';

@media print {
  @page {
    size: 400mm 400mm;
  }
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  div {
    page-break-inside: avoid;
  }
  .scroll-container {
    overflow: visible;
    height: fit-content;
  }
}
.printButton {
  position: absolute;
  top: 35px;
  right: 15px;
}
.havePrintButton {
  margin-right: 90px;
}
.haveEventButton {
  margin-right: 245px;
}
.aesirxui {
  min-height: 65vh;
  .printButton {
    .dropdown-menu {
      min-width: 20rem;
    }
  }
}
.daterange-picker-wrapper .form-control {
  width: 285px;
}
#tooltipTable {
  max-width: 400px;
  text-align: center;
  --rt-opacity: 1;
}
#tooltipChart {
  z-index: 1;
  padding: 30px;
  border-radius: 6px;
  text-align: center;
  --rt-opacity: 1;
  background-color: $white;
  color: $body-color;
  box-shadow: 0px 20px 25px -4px #1a22261a;
  max-width: 85vw;
  .react-tooltip-arrow {
    display: none;
  }
}
.aesirxui {
  .nav-pills.nav-custom {
    .nav-link {
      &.active {
        color: #0f674c;
      }
    }
  }
  #customCol .nav-pills .nav-link.active {
    color: #0f674c !important;
    background-color: var(--bs-white);
    font-weight: 600;
    border-left: 2px solid;
    border-radius: 0px;
  }
  #biapp {
    .table {
      --aesirxui-table-bg: #fff;
      td {
        border: 0;
      }
    }
  }
}
[data-bs-theme='dark'] {
  .aesirxui {
    .text-gray-900 {
      color: #fff;
    }
    #biapp {
      .table {
        --aesirxui-table-bg: var(--aesirxui-color-blue-6);
      }
    }
    .nav-pills {
      .nav-link {
        &.active {
          background-color: transparent;
        }
      }
    }
  }
}
#bi-app {
  .daterange-picker-wrapper {
    .form-control {
      min-width: 330px;
      &.en {
        min-width: 330px;
      }
      &.vi {
        min-width: 330px;
      }
    }
  }
  .mode-switcher {
    input[type='checkbox']:checked::before {
      display: none;
    }
  }
}
.wp-admin {
  #biapp.mini_left {
    .w-260 {
      width: 90px !important;
    }
  }
}
#biapp {
  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
  }
  .text-ellipsis-block {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-box-orient: vertical;
  }
  .line-clamp-1 {
    -webkit-line-clamp: 1;
  }
  .table-link {
    .table-link-bg {
      background-color: #e7efff;
    }
    &:hover {
      .table-link-bg {
        background-color: #a9c6ff;
      }
      .table-link-icon {
        display: block;
      }
      .table-link-text {
        &::after {
          content: '';
          position: absolute;
          right: -18px;
          top: 4px;
          width: 12px;
          height: 12px;
          background-image: url('../../public/assets/images/external_link.svg');
          background-repeat: no-repeat;
        }
      }
    }
    .table-link-icon {
      position: absolute;
      right: -20px;
      top: -2px;
      display: none;
    }
  }
  .browser-table .table > :not(caption) > * > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pagination {
    button {
      &:hover {
        background-color: inherit;
      }
    }
  }
  .data_stream_checkbox {
    .form-check-input {
      width: 20px;
      height: 20px;
    }
  }
  .datastream_all_menu {
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #1ab394;
  }
}
.menu_intergration {
  display: flex;
  .item_menu.intergration {
    .btn,
    a {
      &:hover,
      &.active,
      &:active {
        background-color: #1d55a933;
        color: $primary;
      }
    }
    button.btn.show {
      background-color: #1d55a933;
      color: $primary;
      border-color: $border-color;
    }
    .icon {
      width: 24px;
      height: 24px;
    }
    .dropdown-menu {
      border-radius: 12px;
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background: #fff;
        position: absolute;
        z-index: 1;
        left: calc(50% - 10px);
        top: -6px;
        border: 1px solid $border-color;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: white;
        border-radius: 12px;
      }
      .subitem_menu {
        background-color: $white;
        z-index: 3;
        position: relative;
        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        &:hover,
        &.active {
          background-color: #1d55a933;
        }
      }
    }
  }
}
.bi-intergration_layout {
  .printButton {
    top: 100px;
  }
  @media (max-width: 1600px) {
    .printButton {
      top: 158px;
    }
  }
}

@media (min-width: 992px) {
  #biapp {
    .w-lg-70 {
      width: 70%;
    }
    .w-lg-30 {
      width: 30%;
    }
  }
}

.back_icon {
  width: 30px;
  height: 30px;
  background-color: #e8f1f6;
  border-radius: 100%;
}

.flow_detail_item_content_action {
  background-color: #0f674c;
  padding: 2px 10px;
  border-radius: 4px;
  &.conversion {
    background-color: #6a3e73;
  }
  &.visit {
    background-color: #003059;
  }
}
.flow_detail_item_content_name {
  padding: 2px 10px;
  border-radius: 4px;
  background: #e5e5e5;
}
.visit {
  background-color: #5f5e70;
}
.flow_detail_item_content_link {
  color: #1d55a9 !important;
}
.ux-percent {
  &:hover {
    .ux-percent-detail {
      visibility: visible;
      opacity: 1;
    }
  }
  .ux-percent-detail {
    visibility: hidden;
    opacity: 0;
    padding: 13px 19px;
    top: 50%;
    background-color: #132342;
    min-width: 225px;
    transform: translateY(-50%);
    left: calc(100% - 24px);
    border-radius: 6px;
    font-size: 14px;
    transition: all ease 0.2s;
  }
}
$default-size: 25px;
$default-size: 25px;
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

.aesirxui {
  .search_url {
    .form-control:focus {
      box-shadow: none;
    }
  }
  .charts-container {
    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }
  .pie-wrapper {
    @include size($default-size, $default-size);
    float: left;
    position: relative;

    &:nth-child(3n + 1) {
      clear: both;
    }

    .pie {
      @include size(100%, 100%);
      clip: rect(0, $default-size, $default-size, $default-size / 2);
      left: 0;
      position: absolute;
      top: 0;
      .half-circle {
        @include size(100%, 100%);
        border: ($default-size / 10) solid #1d55a9;
        border-radius: 50%;
        clip: rect(0, $default-size / 2, $default-size, 0);
        left: 0;
        position: absolute;
        top: 0;
      }
    }
    &.progress-75 {
      .pie {
        &.above-50 {
          clip: rect(auto, auto, auto, auto);
          .right-side {
            transform: rotate(180deg);
          }
        }
        &.below-50 {
          .right-side {
            display: none;
          }
        }
      }
    }
    .shadow-pie {
      height: 100%;
      width: 100%;
      border: 0.1em solid #f0f2f5;
      border-radius: 50%;
    }
  }
}
